:root {
  --footer-bg-color: #333333;
  --footer-text-color: #fff;
  --footer-logo-light-visibility: visible;
  --footer-logo-light-display: block;
  --footer-logo-dark-visibility: hidden;
  --footer-logo-dark-display: none;
}
[data-theme="light"] {
  --footer-bg-color: #f1f2f5;
  --footer-text-color: #283149;
  --footer-logo-light-visibility: hidden;
  --footer-logo-light-display: none;
  --footer-logo-dark-visibility: visible;
  --footer-logo-dark-display: block;
}

.footer-logo-light {
  visibility: var(--footer-logo-light-visibility);
  display: var(--footer-logo-light-display);
}

.footer-logo-dark {
  visibility: var(--footer-logo-dark-visibility);
  display: var(--footer-logo-dark-display);
}

.footer {
  background-color: var(--footer-bg-color);
  color: var(--footer-text-color);
  font-size: 14px;
}

.footer-content {
  padding-top: 96px;
  padding-bottom: 72px;
}

.footer-logo {
  width: 80px;
}

.footer-slogan {
  margin-top: 12px;
  font-size: 32px;
  font-weight: 200;
}

.footer-title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 22px;
}

.footer-links li {
  margin-bottom: 12px;
}

.footer-links a {
  text-decoration: none;
  color: var(--footer-text-color);
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.footer-links a:hover {
  text-decoration: none;
  color: var(--footer-text-color);
  opacity: 1;
}

.footer-line {
  width: 100%;
  opacity: 0.25;
  border-color: var(--footer-text-color);
  margin-top: 12px;
}

.footer-icons {
  font-size: 15px;
  color: var(--footer-text-color);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.footer-icons:hover {
  opacity: 0.6;
}
