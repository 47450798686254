/* for color changing as scroll */
.color-first {
  transition: background-color 1.2s ease;
  width: 100%;
  height: 100vh;
  background-color: #404b69;
}
.color-second {
  transition: background-color 1.2s ease;
  width: 100%;
  height: 100vh;
  background-color: #283149;
}
.color-third {
  transition: background-color 1.2s ease;
  width: 100%;
  height: 100vh;
  background-color: #f73859;
}
.color-fourth {
  transition: background-color 1.2s ease;
  width: 100%;
  height: 100vh;
  background-color: #f3ecc8;
}

.homepage-scroll-section-master {
  overflow: hidden;
}

.homepage-scroll-section {
  height: 100vh !important;
  color: #fff;
}

.landing-iso-1 {
  transform: translate(-275px, 215px);
}

.landing-iso-div {
  margin-top: -107px;
}

.landing-iso-div-div {
  overflow: hidden;
}

.homepage-landing-text {
  margin-top: -10rem;
}

.color-dark {
  color: #283149 !important;
}

.homepage-scroll-title {
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 1rem;
}

.homepage-scroll-subtitle {
  font-size: 62px;
  font-weight: 500;
}

.landing {
  height: 70vh !important;
  color: #283149;
}

.landing-newDesign-img {
  transform: scale(1.01);
}

.landing-title {
  font-weight: 700 !important;
}

.landing-row {
  height: 100% !important;
}

.landing-portfolio {
  color: #fff;
  background-color: #222222;
  border-radius: 35px;
}

.landing-portfolio h3 {
  font-size: 100px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 80px;
  color: #222222;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
}

.red {
  color: #f73859;
}

.landing-services {
  color: #fff;
  background-color: #222222;
  /* background-image: url(../../assets/HomePage/misty.png); */
  background-size: cover;
  border-radius: 35px;
}

.landing-services h3 {
  font-size: 46px;
  font-weight: 500;
}

.landing-contact {
  background-color: #222222;
  color: #fff;
}

.mail-button {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  background-color: rgba(255, 255, 255, 1);
  border: none;
  color: #222222;
  padding: 7px 20px;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 100px;
  transition-property: background-color;
  transition: background-color 0.3s ease-in-out;
}

.mail-button:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.contact-icons {
  font-size: 15px;
  color: white;
  opacity: 1;
  transition-property: opacity;
  transition: opacity 0.3s ease-in-out;
}

.contact-icons:hover {
  opacity: 0.6;
}

.landing-overlay {
  background-color: #fff;
  overflow: hidden;
}

.bg-logos {
  width: 45%;
  opacity: 0.05;
}

.bg-logo-1 {
  transform: translate(-300px, 0);
}
.bg-logo-1 img {
  animation: rotation1 60s infinite linear;
}
.bg-logo-2 {
  transform: translate(400px, 0);
}
.bg-logo-2 img {
  animation: rotation2 60s infinite linear;
}
.bg-logo-3 {
  transform: translate(1100px, 0);
}
.bg-logo-3 img {
  animation: rotation3 60s infinite linear;
}
.bg-logo-4 {
  transform: translate(0px, 650px);
}
.bg-logo-4 img {
  animation: rotation4 60s infinite linear;
}
.bg-logo-5 {
  transform: translate(800px, 650px);
}
.bg-logo-5 img {
  animation: rotation5 60s infinite linear;
}
@keyframes rotation1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes rotation2 {
  from {
    transform: rotate(40deg);
  }
  to {
    transform: rotate(399deg);
  }
}
@keyframes rotation3 {
  from {
    transform: rotate(80deg);
  }
  to {
    transform: rotate(439deg);
  }
}
@keyframes rotation4 {
  from {
    transform: rotate(120deg);
  }
  to {
    transform: rotate(479deg);
  }
}
@keyframes rotation5 {
  from {
    transform: rotate(160deg);
  }
  to {
    transform: rotate(409deg);
  }
}

/* Responsive */

@media (max-width: 576px) {
  .landing-iso-div-div {
    margin-top: -50px;
  }
  .homepage-landing-text {
    margin-top: -4rem;
  }
  .homepage-scroll-section {
    height: 110vh !important;
  }
  .color-xs {
    height: 110vh !important;
  }
  .homepage-scroll-subtitle {
    font-size: 50px;
  }
  .landing {
    height: 100vh !important;
  }
}

@media only screen and (max-width: 576px) and (max-height: 634px) {
  .color-first {
    height: 130vh !important;
  }
  .color-second {
    height: 130vh !important;
  }
  .color-third {
    height: 130vh !important;
  }
  .color-fourth {
    height: 130vh !important;
  }
  .homepage-scroll-section {
    height: 130vh !important;
  }
}
