.services-carousel {
  margin-top: 70px;
  margin-bottom: 70px;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.slick-dots li button:before {
  color: #fff !important;
}
