.w-100 {
  width: 100%;
}

.portfolio-item {
  margin-bottom: 1.5rem;
  border-radius: 20px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}

.portfolio-item:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1), 0 32px 32px rgba(0, 0, 0, 0.1),
    0 64px 64px rgba(0, 0, 0, 0.1);
}

.portfolio-img {
  border-radius: 20px;
}

.portfolio-overlay {
  color: white;
  background: linear-gradient(
    0,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
  border-radius: 20px !important;
  opacity: 0;

  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -ms-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

.portfolio-overlay:hover {
  opacity: 1;
}

.portfolio-text {
  padding-left: 15px !important;
  padding-right: 15px !important;
  bottom: 0 !important;
}

.portfolio-title {
  font-weight: 600;
  margin-bottom: 0 !important;
  font-size: 14px;
}
