.button {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 1);

  cursor: pointer;
  transition-property: color;
  transition: color 0.3s ease-in-out;
}

.button-arrow {
  transition-property: transform;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  display: inline-block;
  text-decoration: none;
}

.button:hover {
  color: rgba(255, 255, 255, 0.6) !important;
  text-decoration: none;
}

.button:hover > .button-arrow {
  transform: translateX(6px) !important;
}

.buttonClassRed {
  color: rgba(247, 56, 88, 1) !important;
}

.buttonClassRed:hover {
  color: rgba(247, 56, 88, 0.6) !important;
}
