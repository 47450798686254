.services-header {
  height: 100vh;
  color: #fff;
  background: url(../../assets/OurServices/timelapse.mp4) !important;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.services-video {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
  z-index: -1000;
  visibility: visible;
}

.services-bg-1 {
  background-image: url(../../assets/OurServices/bg-1.jpg);
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -2000;
  transition: background-image 1s ease;
}

.services-bg-2 {
  background-image: url(../../assets/OurServices/bg-2.jpg);
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -2000;
  transition: background-image 1s ease;
}

.services-bg-img {
  transition: background-color 1s ease;
}

.services-all-services {
  color: #fff;
}

.services-card {
  background-color: rgba(51, 51, 51, 0.8);
  border-radius: 50px;
}

.services-card-white {
  background-color: rgba(241, 242, 245, 0.8) !important;
  color: #222222;
}

.logo-tag-title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.logo-tag-desc {
  font-size: 14px;
  font-weight: 400;
}

.ui-card {
  background-image: url(../../assets/OurServices/ui-bg.png);
  background-size: cover;
}

.ui-tag-div {
  margin-top: 400px;
}

.ui-tag {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.photography-card {
  background-image: url(../../assets/OurServices/photography.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.photography-card-div {
  padding-bottom: 500px;
}

.videography-card {
  background-image: url(../../assets/OurServices/videography.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.videography-card-div {
  padding-bottom: 500px;
}

/* Responsive */

@media (max-width: 576px) {
  .services-video {
    visibility: hidden;
    display: none;
  }
  .services-card {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .ui-tag-div {
    margin-top: 40px;
  }
  .photography-card {
    background-size: cover;
  }
  .photography-card-div {
    padding-bottom: 100px;
  }
  .videography-card {
    background-size: cover;
  }
  .videography-card-div {
    padding-bottom: 100px;
  }
  .videography-card-div p {
    color: #fff;
  }
  .videography-card {
  }
  .videography-bg {
    height: 100%;
    width: 100%;
    background-color: #fff;
  }
}
