.button-pill {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  border: none;

  padding: 7px 20px;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 100px;
  transition-property: background-color;
  transition: background-color 0.3s ease-in-out;
}

.button-pill-arrow {
  transition-property: transform;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  display: inline-block;
}

.button-pill:hover > .button-pill-arrow {
  transform: translateX(6px) !important;
}

/* Dark Button */
.button-pill-dark {
  background-color: rgba(40, 49, 73, 1);
  color: #fff;
}
.button-pill-dark:hover {
  background-color: rgba(40, 49, 73, 0.7);
}

/* Light Button */
.button-pill-light {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.button-pill-light:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

/* Light Button */
.button-pill-darkOnLight {
  background-color: rgba(40, 49, 73, 0.15);
  color: #283149;
}

.button-pill-darkOnLight:hover {
  background-color: rgba(40, 49, 73, 0.25);
}
