.portfolio-header {
  color: #283149;
  margin-top: 200px;
}

.h-100 {
  height: 100%;
}

.portfolio-banner {
  background-color: #f1f2f5;
  border-radius: 35px;
  color: #283149;
}
