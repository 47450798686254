.services-carousel-item {
  border-radius: 25px;
  overflow: hidden !important;
  background-color: #fff;
  color: #222222;
  margin: 1rem;
  transition-property: transform;
  transition: transform 0.3s ease-in-out;
}

.services-carousel-item:hover {
  transform: scale(1.03);
}

.services-carousel-item-img-div {
  height: 120px;
  overflow: hidden !important;
}

.services-carousel-item-img-div img {
  margin-top: -60px;
}

.carousel-item-text h4 {
  font-size: 18px;
  font-weight: 500;
}

.carousel-item-text h5 {
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #3d3d3d;
}

.carousel-item-text p {
  font-size: 10px;
  font-weight: 400;
  color: #3d3d3d;
}
