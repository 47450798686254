.contact-header {
  background-color: #191919;
  color: #fff;
  width: 100vw;
}

.contact-mail-button {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  color: #fff;
  padding: 7px 20px;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 100px;
  transition-property: background-color;
  transition: background-color 0.5s ease-in-out;
}

.contact-mail-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.contact-page-icons {
  font-size: 15px;
  color: white;
  opacity: 1;
  transition-property: opacity;
  transition: opacity 0.3s ease-in-out;
}

.contact-page-icons:hover {
  opacity: 0.6;
}

/* */
input {
  outline: none;
  border: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #cccccc;
}
input:-moz-placeholder {
  color: #cccccc;
}
input::-moz-placeholder {
  color: #cccccc;
}
input:-ms-input-placeholder {
  color: #cccccc;
}

textarea::-webkit-input-placeholder {
  color: #cccccc;
}
textarea:-moz-placeholder {
  color: #cccccc;
}
textarea::-moz-placeholder {
  color: #cccccc;
}
textarea:-ms-input-placeholder {
  color: #cccccc;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

/* shapes */
.triangle-1C {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 45px solid rgba(255, 255, 255, 0.05);
  transform: translate(120px, -140px) rotate(32deg);
}

.square-1C {
  height: 50px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.05);
  right: 0;
  transform: translate(-275px, 0px);
}

.square-2C {
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.05);
  right: 0;
  transform: translate(-100px, 650px) rotate(45deg);
}
.circle-1C {
  height: 35px;
  width: 35px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  transform: translate(225px, 250px);
}

.circle-2C {
  height: 350px;
  width: 350px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.05);
  transform: translate(30%, 150px);
  right: 0;
}

.circle-3C {
  height: 200px;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  transform: translate(-40%, 525px);
}

iframe {
  border: none !important;
}

.wrap-login100 {
}

.login100-form {
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #dbdbdb;
}

.input100 {
  display: block;
  width: 100%;
  height: 30px;
  background: transparent;
  font-weight: 200;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: #dbdbdb;
  line-height: 1.2;
  padding: 0 2px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #394c6e;
  background: -webkit-linear-gradient(45deg, #394c6e, #394c6e);
  background: -o-linear-gradient(45deg, #394c6e, #394c6e);
  background: -moz-linear-gradient(45deg, #394c6e, #394c6e);
  background: linear-gradient(45deg, #394c6e, #394c6e);
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.container-login100-form-btn2 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 30px;
  margin: 0;
  width: 290px;
}

.wrap-login100-form-btn {
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 300%;
  background: #394c6e;
  background: -webkit-linear-gradient(top, #253d49, #394c6e, #253d49, #394c6e);
  background: -o-linear-gradient(top, #253d49, #394c6e, #253d49, #394c6e);
  background: -moz-linear-gradient(top, #253d49, #394c6e, #253d49, #394c6e);
  background: linear-gradient(top, #253d49, #394c6e, #253d49, #394c6e);
  bottom: -100%;
  left: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 200px;
  height: 25px;

  font-weight: 600;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
}

.login100-form-btn2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 00px;
  height: 25px;

  font-weight: 600;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
  bottom: 0;
}

.radio-buttons-div {
  color: #dbdbdb;
}

.radio-button {
}

.freebirdFormviewerViewHeaderHeader {
  background-color: #000 !important;
}

.freebirdFormviewerComponentsQuestionBaseRoot {
  background-color: #000 !important;
}
